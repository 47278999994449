import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
// import axios from 'axios';
import { useDispatch } from 'react-redux';
// import HomeContact from './HomeContact';
import { requestForced } from '../../actions/auth';
// import API_CONFIG from '../../config/configurations';

// const { url } = API_CONFIG;

const Confirm = props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(0);
  const {
    match: {
      params: { id: confirmHash }
    }
  } = props;
  const callConfirm = async () => {
    try {
      dispatch(
        requestForced({
          confirmation_hash: confirmHash
        })
      );

      setLoading(1);
    } catch (e) {
      setLoading(2);
      console.log(e);
    }
  };
  useEffect(() => {
    callConfirm();
  }, []);
  return (
    <div className="d-flex flex-column justify-content-center p-5 mx-auto mt-5 container align-items-center">
      <h4>…Espéranos unos segundos, estamos verificando tu correo!….</h4>
      {loading === 0 && <Spinner animation="border" />}
      {loading === 2 && <h4>Hubo un error</h4>}
    </div>
  );
};

export default Confirm;
