import React from 'react';
import { useLocation } from 'react-router-dom';
import { Image, Card } from 'react-bootstrap';
import IcoMoon from '../Utils/Icon';

const DriverItemCell = ({
  itemName,
  height,
  length,
  width,
  weight,
  imageUrl,
  isFragile,
  tripId
}) => {
  const location = useLocation();
  const isTrip = location.pathname.includes('trips');
  const dimensions = length ? `${length}cm x ${width}cm x ${height}cm` : '';
  const totalWeight = weight ? `${weight} kg` : '';
  const spacer = dimensions === '' ? '' : ' - ';
  return (
    <div
      data-tag="allowRowEvents"
      className="d-flex driver-item-cell w-100 mx-1"
    >
      <div
        data-tag="allowRowEvents"
        className="d-flex justify-content-center align-items-center mr-2"
      >
        <div
          data-tag="allowRowEvents"
          className="d-flex justify-content-center align-items-center img-container"
        >
          <Image
            data-tag="allowRowEvents"
            className="card-img"
            src={imageUrl}
          />
        </div>
      </div>
      <div
        data-tag="allowRowEvents"
        className="d-flex justify-content-center align-items-center w-100 mr-3"
      >
        <div
          data-tag="allowRowEvents"
          className="d-flex flex-column justify-content-center align-items-start w-100 ml-2"
        >
          <div
            data-tag="allowRowEvents"
            className="d-flex justify-content-between w-100 align-items-baseline name-driver-item-cell w-100"
          >
            <Card.Title data-tag="allowRowEvents" className="table-card-tittle">
              {itemName}
            </Card.Title>
            <div
              data-tag="allowRowEvents"
              className="d-flex justify-content-center align-items-center text-dark"
            >
              {tripId && !isTrip && (
                <IcoMoon
                  data-tag="allowRowEvents"
                  className="text-dark "
                  tooltipText="Viaje asociado"
                  tooltipPlacement="top"
                  size="18"
                  icon="car"
                  classNameContainer="d-flex justify-content-center align-items-center text-dark driver-car-box"
                />
              )}
              {isFragile && (
                <IcoMoon
                  data-tag="allowRowEvents"
                  className="icon-primary text-dark"
                  size="18"
                  icon="heart-dislike"
                  tooltipText="Frágil"
                  tooltipPlacement="top"
                  classNameContainer="heart-box"
                  styleContainer={{ padding: '6px 8px' }}
                />
              )}
            </div>
          </div>
          <Card.Text data-tag="allowRowEvents">
            {dimensions}
            {spacer}
            {totalWeight}
          </Card.Text>
        </div>
      </div>
    </div>
  );
};

export default DriverItemCell;
