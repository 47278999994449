import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import AccordionFaqs from './AccordionFaqs';

const FourthSection = ({
  questionOne,
  answerOne,
  questionTwo,
  answerTwo,
  questionThree,
  answerThree,
  questionFour,
  answerFour,
  questionFive,
  answerFive,
  questionSix,
  answerSix,
  questionSeven,
  answerSeven,
  questionEight,
  answerEight,
  questionNine,
  answerNine,
  questionTen,
  answerTen
}) => {
  return (
    <div id="faqs_briiing">
      <Row className="Faqs__row">
        <Col>
          <Card className="Faqs__card">
            <Card.Body>
              <Row>
                <div className="col-lg-4 align-self-center">
                  <Card.Title className="Faqs__cardTitle">
                    <h3>
                      Preguntas
                      <br />
                      frecuentes
                    </h3>
                  </Card.Title>
                  {/* <Card.Text className="mb-4">Preguntas frecuentes</Card.Text> */}
                </div>
                <div className="col-lg-8">
                  <AccordionFaqs
                    questionOne={questionOne}
                    answerOne={answerOne}
                    questionTwo={questionTwo}
                    answerTwo={answerTwo}
                    questionThree={questionThree}
                    answerThree={answerThree}
                    questionFour={questionFour}
                    answerFour={answerFour}
                    questionFive={questionFive}
                    answerFive={answerFive}
                    questionSix={questionSix}
                    answerSix={answerSix}
                    questionSeven={questionSeven}
                    answerSeven={answerSeven}
                    questionEight={questionEight}
                    answerEight={answerEight}
                    questionNine={questionNine}
                    answerNine={answerNine}
                    questionTen={questionTen}
                    answerTen={answerTen}
                  />
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FourthSection;
