import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import imagecomputersm from '../../../assets/images/computer-landing-briiing-mobile.webp';
import imageComputer from '../../../assets/images/computerLandingBriiing.webp';

const FirstSection = ({ firstSectionTitle, firstSectionSubtitle }) => {
  return (
    <div id="home">
      <div className=" home-header-container__img d-block d-md-none">
        <Row>
          <Col>
            <div className="home-header-container__getStarted">
              <Button as={Link} to="/session" className="btn btn-secondary">
                Comenzar ahora
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <img
              alt="Computadora con interfaz de Briiing!"
              src={imagecomputersm}
              width="100%"
              height=""
            />
          </Col>
        </Row>
      </div>
      <div className="home-header-container">
        <div>
          <Row className="home-header-container__tittle">
            <Col>
              <h1>{firstSectionTitle || ''}</h1>
              <p>{firstSectionSubtitle || ''}</p>
            </Col>
          </Row>
        </div>
      </div>
      <div className=" home-header-container__img d-none d-md-block">
        <Row>
          <Col>
            <img
              alt="Computadora con interfaz de Briiing!"
              src={imageComputer}
              width="100%"
              height=""
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FirstSection;
