import React from 'react';
import { Col, Row } from 'react-bootstrap';
import IcoMoon from '../../Utils/Icon';
import ImageWithZoom from '../../Utils/Image/ImageWithZoom';

const ClientItemDescription = ({
  itemIndex,
  images,
  title,
  height,
  length,
  width,
  weight,
  comments,
  isFragile,
  tripId
}) => {
  const showComments = itemIndex === 0;
  const dimensions = length ? `${length}cm x ${width}cm x ${height}cm` : '';
  const totalWeight = weight ? `${weight} kg` : '';
  const spacer = dimensions === '' ? '' : ' - ';
  return (
    <>
      <div className="mx-0 mb-4 p-0 items-description">
        <Row>
          <Col xs={10}>
            <h6 className="font-weight-bold">{title}</h6>
            <p className="my-2">
              {dimensions}
              {spacer}
              {totalWeight}
            </p>
          </Col>
          <Col xs={2} className="d-flex flex-row">
            {tripId && (
              <IcoMoon
                data-tag="allowRowEvents"
                className="text-dark "
                tooltipText="Viaje asociado"
                tooltipPlacement="top"
                size="15"
                icon="car"
                classNameContainer="d-flex justify-content-center align-items-center driver-car-box"
              />
            )}
            {isFragile && (
              <IcoMoon
                data-tag="allowRowEvents"
                className="text-dark icon-heart"
                size="18"
                icon="heart-dislike"
                tooltipText="Frágil"
                tooltipPlacement="top"
                classNameContainer="heart-box"
              />
            )}
          </Col>
        </Row>

        {images?.length > 0 && (
          <div className="d-flex justify-content-start align-items-between w-100 mb-2 images-scrollable">
            {images.map(image => (
              <div
                key={image.filename}
                className="d-flex justify-content-center align-items-center img-container"
              >
                <ImageWithZoom src={image.fileUrl} />
              </div>
            ))}
          </div>
        )}
        {images?.length === 0 && (
          <div className=" d-flex justify-content-center align-items-center img-container ml-1" />
        )}

        {showComments ? (
          <p className="mt-3">
            <strong>Comentarios : </strong> {comments}
          </p>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default ClientItemDescription;
