/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { requestSignOut, validateToken } from '../../actions/auth';
import { DefaultModal } from '..';
import IcoMoon from '../Utils/Icon/IcoMoon';
import ChangePassword from '../../screens/Auth/ChangePassword';
import ChangeProfile from '../../screens/Auth/ChangeProfile';
import { updateUserRequest } from '../../requests/user';
import { driverDashboardRequest } from '../../requests/dashboards';
import DashboardInfoBox from '../Dashboard/DashboardInfoBox';

const ProfileSessionInfo = ({ driver, classNameRow, user }) => {
  const location = useLocation();
  const history = useHistory();
  const currentPathName = location.pathname.split('/')[1];
  const [isNewRoleCreated, setIsNewRoleCreated] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalChangeProfileShow, setChangeProfileShow] = useState(false);
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);
  const [dashboardData, setDashBoardData] = useState({});
  const showCreateNewRoleBtn =
    isNewRoleCreated || user?.currentRoles?.length !== 2;

  const rediretToDashboard = () => {
    validateToken();
    if (currentPathName === 'client') {
      history.push('/client/home');
    }
    if (currentPathName === 'driver') {
      history.push('/driver/home');
    }
    window.location.reload();
  };

  const handleCloseRoleModal = () => {
    if (isNewRoleCreated) {
      rediretToDashboard();
    } else {
      setChangeProfileShow(false);
    }
  };

  const handleUpdateRequest = () => {
    setOnRequest(true);
    const params = {
      update_role: true,
      user: {
        client_attributes: {},
        driver_attributes: {
          vehicles_attributes: {}
        },
        role_ids: [1, 2]
      }
    };
    if (driver) {
      params.user.client_attributes.name = user?.driverAttributes?.name;
      params.user.client_attributes.last_name =
        user?.driverAttributes?.lastName;
    }
    if (!driver) {
      params.user.driver_attributes.name = user?.clientAttributes?.name;
      params.user.driver_attributes.last_name =
        user?.clientAttributes?.lastName;
      params.user.driver_attributes.license_type = 0;
    }
    updateUserRequest({
      dispatch,
      params,
      successCallback: () => {
        setTimeout(() => {
          setOnRequest(false);
          setIsNewRoleCreated(true);
        }, 400);
      }
    });
  };

  const handleFetchDashboard = () => {
    if (driver) {
      driverDashboardRequest({
        dispatch,
        successCallback: response => {
          setDashBoardData(camelCaseRecursive(response.data));
        }
      });
    }
  };

  useEffect(handleFetchDashboard, []);

  return (
    <Row className={classNameRow}>
      <Col md={driver ? 12 : 5} xl={driver ? 12 : 4}>
        {driver && (
          <>
            <Row className="driver-dashboard data-table ml-1">
              <DashboardInfoBox
                xl={12}
                className="mt-5 pl-0"
                showModal={driver}
                userInfo={user}
                driver
                title="Monto por cobrar"
                icon="money"
                total={dashboardData.amountReceivable || 0}
              />
            </Row>
            <div className="box-shipping-sent">
              <div className="shipping">
                <div className={`bg-icon-car ${driver ? 'driver' : ''}`}>
                  <IcoMoon icon="car" />
                </div>
                <h5>
                  Briiing realizados:
                  <span className="ml-2">
                    {user?.driverAttributes?.shippingCount}
                  </span>
                </h5>
              </div>
            </div>
          </>
        )}
        <div className="bg-session">
          {showCreateNewRoleBtn && (
            <div
              onClick={() => setChangeProfileShow(true)}
              role="button"
              tabIndex="0"
              className={`box-session ${driver ? 'driver' : ''}`}
            >
              <IcoMoon size={18} icon={`${driver ? 'person' : 'car'}`} />
              <p>Crear perfil {driver ? 'Cliente' : 'Briiinger'}</p>
            </div>
          )}

          <div
            className={`box-session ${driver ? 'driver' : ''}`}
            onClick={() => dispatch(requestSignOut())}
            onKeyDown={() => dispatch(requestSignOut())}
            role="button"
            tabIndex={0}
          >
            <IcoMoon icon="exit" />
            <p>Cerrar sesión</p>
          </div>
          <div
            onClick={() => setModalShow(true)}
            role="button"
            tabIndex="0"
            className={`box-session ${driver ? 'driver' : ''}`}
          >
            <IcoMoon icon="repeat1" />
            <p>Cambiar clave</p>
          </div>

          <DefaultModal
            noButtons
            title=""
            titleBtnSave=""
            titleBtnClose=""
            body={
              <ChangeProfile
                changeProfile={handleUpdateRequest}
                onRequest={onRequest}
                isNewRoleCreated={isNewRoleCreated}
                handleCloseRoleModal={handleCloseRoleModal}
              />
            }
            show={modalChangeProfileShow}
            handleClose={() => handleCloseRoleModal()}
            handleConfirm={() => {}}
          />

          <DefaultModal
            noButtons
            title=""
            titleBtnSave=""
            titleBtnClose=""
            body={<ChangePassword setModalShow={setModalShow} />}
            show={modalShow}
            handleClose={() => setModalShow(false)}
            handleConfirm={() => {}}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ProfileSessionInfo;
